import React from 'react'
import GmailModal from './GmailModal'


const CreateGmail = ({render,setrender}) => {
    return (
        <div style={{ margin: "2rem 3rem" }}>
            <GmailModal ButtonName="Add Gmail" render={render} setrender={setrender} />
        </div>
    )
}

export default CreateGmail
