import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CreateReviewCategoryModal = ({ open, onClose, fetchCategories }) => {
    const [categoryName, setCategoryName] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));

    const handleCreateCategory = async () => {
        try {
            // Send request to create a new review category
            await axios.post(`${process.env.REACT_APP_BASE_URL}review-category/create`, { categoryName });
            if (ToasterToggle) {
                toast.success('Review category created successfully');
            }
            fetchCategories(); // Refresh the categories list
            setCategoryName(''); // Clear the input field
            onClose(); // Close the modal
        } catch (error) {
            if (ToasterToggle) {
                toast.error('Failed to create review category');
            }
            console.error('Error creating review category:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="create-review-category-modal-title"
            aria-describedby="create-review-category-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="create-review-category-modal-title" sx={{ color: "black" }} variant="h6" component="h2">
                    Create Review Category
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="categoryName"
                    label="Category Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <Button onClick={handleCreateCategory} style={{ marginTop: 20 }} variant="contained">
                    Create
                </Button>
            </Box>
        </Modal>
    );
};

export default CreateReviewCategoryModal;

