import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Modal } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const baseURL = process.env.REACT_APP_BASE_URL;

const KeywordsForm = ({ render, setRender }) => {
    const [keywords, setKeywords] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const keywordsArray = keywords.split(',').map(kw => kw.trim()); // Split string into array by commas and trim whitespace

        try {
            const response = await axios.post(`${baseURL}keyword/create-keywords`, { keywords: keywordsArray });
            if (ToasterToggle) { // Check if toaster should be displayed
                toast.success(`${response.data.message}`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setKeywords("");
            setRender(!render)
            setModalOpen(false); // Close modal after successful submission
        } catch (error) {
            console.error('Failed to submit keywords', error);
            if (ToasterToggle) {
                toast.error(`${error.response.data.message}`, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        setIsSubmitting(false);
    };

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <Box style={{ color: "white", textAlign: "center", marginTop: '2rem' }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Button
                variant="contained"
                onClick={handleOpenModal}
                sx={{ backgroundColor: '#15178e', color: 'white', '&:hover': { backgroundColor: '#0d0d55', color: 'white' }, }}

            >
                Create Keyword
            </Button>

            {/* Modal for Keyword Submission */}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="create-keyword-modal-title"
                aria-describedby="create-keyword-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 1,
                        color: 'black',
                    }}
                >
                    <Typography id="create-keyword-modal-title" variant="h6" component="h2">
                        Create Keyword
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Enter Keywords (comma-separated)"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            InputLabelProps={{
                                style: { color: 'black' },
                            }}
                            InputProps={{
                                style: { color: 'black' },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "black",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "black",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                    },
                                },
                            }}
                        />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, width: "50%", backgroundColor: '#15178e', color: 'white', '&:hover': { backgroundColor: '#0d0d55', color: 'white' } }}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default KeywordsForm;

