import React from 'react';
import { Modal, Box, Typography, List, ListItem, Divider } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';

const UrlDisplayModal = ({ open, urls, onClose }) => {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxHeight: '70%',
        color: 'black',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="url-modal-title"
            aria-describedby="url-modal-description"
        >
            <Box sx={modalStyle}>
                <AiFillCloseCircle
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 8, right: 8 }}
                />
                <Typography id="url-modal-title" variant="h6" component="h2">
                    URLs List
                </Typography>
                <List>
                    {urls.map((url, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                {url}
                            </ListItem>
                            {index < urls.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default UrlDisplayModal;
