import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditAccountModal = ({ open, onClose, accountData, render, setRender }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        profileType: '',
        // range: 1,
        profileName: '',
        region: '',
        // os: '',
        // browser: '',
        // randomize: false,
    });

    useEffect(() => {
        if (accountData) {
            setFormData({
                profileType: accountData.profileType,
                // range: accountData.range || 1,
                profileName: accountData.profileName,
                region: accountData.region,
                // os: accountData.os || '',
                // browser: accountData.browser || '',
                // randomize: !accountData.os,
            });
        }
    }, [accountData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleRandomizeChange = (event) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            randomize: event.target.checked,
            os: event.target.checked ? '' : prevFormData.os,
            browser: event.target.checked ? '' : prevFormData.browser,
        }));
    };

    const handleSave = () => {
        axios.put(`${process.env.REACT_APP_BASE_URL}account/edit/${accountData._id}`, formData)
            .then(() => {
                if (ToasterToggle) {
                    toast.success("Account updated successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setRender((prev) => !prev);
                onClose();
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error(`Failed to update account: ${error.response.data.message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="edit-account-modal-title"
            aria-describedby="edit-account-modal-description"
        >
            <Box sx={modalStyle}>
                <AiFillCloseCircle
                    onClick={onClose}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: 'black',
                    }}
                />

                <Typography id="edit-account-modal-title" variant="h6" component="h2" sx={{ color: "black" }}>
                    Edit Account
                </Typography>
                <TextField
                    margin="dense"
                    id="profileName"
                    name="profileName"
                    label="Profile Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.profileName}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    id="region"
                    name="region"
                    label="Region"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.region}
                    onChange={handleInputChange}
                />
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.randomize}
                            onChange={handleRandomizeChange}
                        />
                    }
                    sx={{ color: "black" }}
                    label="Randomize OS and Browser"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>OS</InputLabel>
                    <Select
                        name="os"
                        value={formData.os}
                        onChange={handleInputChange}
                        disabled={formData.randomize}
                    >
                        <MenuItem value="Mac">Mac</MenuItem>
                        <MenuItem value="Windows">Windows</MenuItem>
                        <MenuItem value="Linux">Linux</MenuItem>
                        <MenuItem value="Android">Android</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Browser</InputLabel>
                    <Select
                        name="browser"
                        value={formData.browser}
                        onChange={handleInputChange}
                        disabled={formData.randomize}
                    >
                        <MenuItem value="Mimic">Mimic</MenuItem>
                        <MenuItem value="Stealthfox">Stealthfox</MenuItem>
                    </Select>
                </FormControl> */}
                <Button onClick={handleSave} color="primary" variant="contained" sx={{ mt: 2 }}>
                    Save Changes
                </Button>
            </Box>
        </Modal>
    );
};

export default EditAccountModal;
