import React from 'react'
import { CircularProgress } from '@mui/material'


const Circular = () => {
  return (
    <div style={{display:"flex" ,  justifyContent:"center" , marginTop : "1rem"}}>
                <CircularProgress />
    </div>
  )
}

export default Circular
