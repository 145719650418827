import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';

const ImportQuestion = ({ render, setRender, questionCategoryId }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
    };

    const handleFileUpload = async () => {
        try {
            if (!file) {
                setError('Please select a file.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('questionCategoryId', questionCategoryId);

            const URL = process.env.REACT_APP_BASE_URL;
            await axios.post(`${URL}question/upload-question`, formData);

            setFile(null);
            document.getElementById('fileInput').value = null;

            setError('');
            setRender(!render);
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file. Please try again.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0px', padding: '10px' }}>
            <label style={{ marginRight: '10px' }}>Choose File:</label>
            <input type="file" id="fileInput" onChange={handleFileChange} />
            <Button variant="contained" style={{ backgroundColor: 'Black', marginLeft: '10px' }} onClick={handleFileUpload}>
                Upload File
            </Button>

            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </div>
    );
};

export default ImportQuestion;

