import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import DataTable from '../DataTable';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import SearchSpecificReview from './SearchSpecificReview';
import SpecificReviewCategoriesTable from './SpecificReviewCategoriesTable';
import CreateSpecificReviewCategoryModal from './CreateSpecificReviewCategoryModal';

const SpecificReviewCategoriesMain = ({ render, setRender, data, rows, setPassSelectedCategoryId, columns, onAssignToReview }) => {
    const [rowsData, setRowsData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('all');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [delCategoryId, setDelCategoryId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [delData, setDeleteData] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});


    useEffect(() => {
        setRowsData(rows);
        fetchCategories();
        loadColumnVisibility();
    }, [render]);

    useEffect(() => {
        handleCategorySelect(selectedCategoryId);
    }, [render]);

    const handleBulkDelete = async (deleteData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}specific-review/bulk-delete`, { reviewIds: deleteData });
            toast.success(response.data.message || 'Selected reviews deleted successfully');
            setRender(prev => !prev);
        } catch (error) {
            toast.error(error.response.data.message || 'Failed to delete selected reviews');
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}specific-review-category/delete/${categoryId}`);
            toast.success(response.data.message || 'Category deleted successfully');
            setRender(prev => !prev);
        } catch (error) {
            toast.error('Failed to delete category');
        }
    };

    const ConfirmHandleDelete = (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData);
    };

    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData);
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}specific-review-category/all-categories`);
            setCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch categories');
        }
    };

    const fetchReviewsByCategory = async (categoryId, page = 1, limit = 100) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}specific-review-category/${categoryId}/reviews?page=${page}&limit=${limit}`;
            const response = await axios.get(url);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
            setTotalItems(response.data.totalItems);

            const flattenReviews = response.data.specificReviews.map((row) => ({
                id: row._id,
                _id: row._id,
                reviewText: row.reviewText,
                searchKeyword: row.searchKeyword,
                location: row.location,
                rating: row.rating,
                link: row.link
            }));

            setRowsData(flattenReviews);
        } catch (error) {
            toast.error('Failed to fetch reviews by category');
        }
    };

    const setSearchReview = (reviewData) => {
        setTotalPages(reviewData.totalPages);
        setCurrentPage(reviewData.currentPage);
        setTotalItems(reviewData.totalItems);

        const flattenReviews = reviewData.specificReviews.map((row) => ({
            id: row._id,
            _id: row._id,
            reviewText: row.reviewText,
            searchKeyword: row.searchKeyword,
            location: row.location,
            rating: row.rating,
            link: row.link,
        }));

        setRowsData(flattenReviews);

    }
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchReviewsByCategory(selectedCategoryId, newPage);
    };

    const handleCategorySelect = (categoryId) => {
        setCurrentPage(1);
        setSelectedCategoryId(categoryId);
        setPassSelectedCategoryId(categoryId);
        fetchReviewsByCategory(categoryId, 1);
    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('specificReviewColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('specificReviewColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    return (
        <div>
            <SearchSpecificReview setSearchReview={setSearchReview} />
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flex: 2, marginRight: "20px" }}>
                    <SpecificReviewCategoriesTable onCategorySelect={handleCategorySelect} categories={categories} handleDeleteCategory={handleDeleteCategory} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{ width: "130px", height: "50px", padding: "10px", marginTop: "15px", backgroundColor: '#1B3C73', color: "white" }}
                            variant="outlined"
                            size="small"
                            onClick={() => setIsCreateModalOpen(true)}
                        >
                            Category
                        </Button>
                    </div>
                </div>
                <div style={{ flex: 10 }}>
                    {data.length > 0 && rowsData.length > 0 ? (
                        <DataTable rows={rowsData} columns={columns} onDeleteSelected={ConfirmHandleDelete} onAssignToReview={onAssignToReview} handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} totalItems={totalItems} saveColumnVisibility={saveColumnVisibility} columnVisibilityModel={columnVisibilityModel} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <CreateSpecificReviewCategoryModal
                open={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                fetchCategories={fetchCategories}
            />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete this review?"
            />
        </div>
    );
};

export default SpecificReviewCategoriesMain;

