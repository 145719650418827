import React, { useState, useEffect } from 'react';
import {
    Modal, Box, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Menu, MenuItem,
    Badge,
} from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
import axios from 'axios';
import UrlDisplayModal from './UrlDisplayModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};

const HistoryModal = ({ open, onClose, accountId }) => {
    const [history, setHistory] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentUrls, setCurrentUrls] = useState([]);
    const [urlModalOpen, setUrlModalOpen] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState([]);

    const handleUrlClick = (urls) => {
        setSelectedUrls(urls);
        setUrlModalOpen(true);
    };

    useEffect(() => {
        if (open) {
            axios.get(`${process.env.REACT_APP_BASE_URL}history/${accountId}`)
                .then((response) => {
                    setHistory(response.data.historyEvents);
                })
                .catch((error) => {
                    console.error('Error fetching account history:', error);
                });
        }
    }, [open, accountId]);

    const handleClick = (event, urls) => {
        setAnchorEl(event.currentTarget);
        setCurrentUrls(urls);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="history-modal-title"
            aria-describedby="history-modal-description"
        >
            <Box sx={style}>
                <AiFillCloseCircle
                    onClick={onClose}
                    style={{ backgroundColor: 'black', borderRadius: '50%', cursor: 'pointer', position: 'absolute', top: 8, right: 8 }}
                />
                <Badge color="primary" badgeContent={history?.length}>
                    <Typography id="history-modal-title" variant="h6" component="h2" sx={{ mb: 2, color: "black" }}>
                        Account History
                    </Typography>
                </Badge>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ flex: 3 }}>Message</TableCell>
                                <TableCell sx={{ flex: 1 }}>Time</TableCell>
                                <TableCell>Cookies Storage</TableCell>
                                <TableCell>Total Domain</TableCell>
                                <TableCell>URLs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map((event, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" sx={{ flex: 3 }}>
                                        {event.message}
                                    </TableCell>
                                    <TableCell sx={{ flex: 1 }}>{new Date(event.time).toLocaleString()}</TableCell>
                                    <TableCell>
                                        {event?.cookiesStorage}
                                    </TableCell>
                                    <TableCell>
                                        {event?.TotalDomain}
                                    </TableCell>
                                    <TableCell>

                                        {event.urls.length > 0 && (
                                            <Badge color="primary" badgeContent={event.urls.length}>
                                                <Button onClick={(e) => handleUrlClick(event.urls)}>View URLs</Button>
                                            </Badge>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: '20rem',
                            overflow: 'auto'
                        }
                    }}
                >
                    {currentUrls.map((url, idx) => (
                        <MenuItem key={idx} onClick={handleClose}>{url}</MenuItem>
                    ))}
                </Menu>
            </Box>
        </Modal>
        <UrlDisplayModal
            open={urlModalOpen}
            urls={selectedUrls}
            onClose={() => setUrlModalOpen(false)}
        />
    </>);
};

export default HistoryModal;
