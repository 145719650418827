import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CreateProxyCategoryModal = ({ open, onClose, fetchCategories }) => {
    const [categoryName, setCategoryName] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const handleCreateCategory = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}proxyCategory/create`, { name: categoryName });
            if (ToasterToggle) {
                toast.success('Category created successfully');
            }
            fetchCategories(); // Refresh the categories list
            setCategoryName('')
            onClose(); // Close the modal
        } catch (error) {
            if (ToasterToggle) {
                toast.error('Failed to create category');
            }
            console.error('Error creating category:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="create-category-modal-title"
            aria-describedby="create-category-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="create-category-modal-title" sx={{ color: "black" }} variant="h6" component="h2">
                    Create Proxy Category
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Category Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <Button onClick={handleCreateCategory} style={{ marginTop: 20 }} variant="contained">
                    Create
                </Button>
            </Box>
        </Modal>
    );
};

export default CreateProxyCategoryModal;
