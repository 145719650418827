import React from 'react'
import ProxyModal from '../components/ProxyModal'

const CreateProxy = ({ render, setrender, passSelectedCategoryId }) => {
    return (
        <div style={{ margin: "2rem 3rem" }}>
            <ProxyModal ButtonName="Add Proxy" passSelectedCategoryId={passSelectedCategoryId} render={render} setrender={setrender} />
        </div>
    )
}

export default CreateProxy
