import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CheckToken = () => {
    const navigate = useNavigate()
    const user = localStorage.getItem("user")
    useEffect(() => {
        console.log("use effect called")

        if (!user) {
            console.log("Not a user")
            navigate("/login")
        }

    }, [user])
    return (
        <div>
        </div>
    )
}

export default CheckToken
