import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme();

export default function SignIn() {
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}auth/signin`, {
                username: data.get('email'),
                password: data.get('password'),

            });
            // console.log("RESPONSE ::: ", response, response.status)
            // console.log(response.status == 200, "____")
            if (response.status == 200) {
                const result = response.data;
                // console.log("RESPONSE ::: ", result, result.status)

                // Assuming the API response contains a 'status' field
                // console.log(result.status == true, "+++")

                if (result.status == true) {

                    // Store user information in localStorage
                    localStorage.setItem('user', true);
                    localStorage.setItem('Toaster', result.toasterToggle);


                    // Redirect or perform any other action on successful sign-in
                    // console.log('Sign-in successful');
                    navigate('/')
                } else {
                    console.error('Sign-in failed:', result.error);
                }
            } else {
                console.error('Sign-in failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during sign-in:', error.message);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        color: "white",
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ color: "white", borderBlockColor: "white" }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                            }} />

                        <Button
                            type="submit"
                            fullWidth
                            //  style={{ backgroundColor: 'black' }}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
