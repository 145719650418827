import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';  // Using lodash for debouncing
import { TextField, InputAdornment, CircularProgress, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';  // MUI search icon

const SearchProxy = ({ setSearchProxy }) => {
    const [query, setQuery] = useState('');  // Search query
    const [loading, setLoading] = useState(false);  // Loading state
    const [noResults, setNoResults] = useState(false);

    const limit = 100;  // Number of items per page

    // Function to fetch accounts based on search query and pagination
    const fetchProxy = async (searchQuery, page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}proxy/search`, {
                params: {
                    query: searchQuery,
                    page: page,
                    limit: limit
                }
            });
            setSearchProxy(response.data);
            if (response.data.proxies.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching accounts:', error);
            setLoading(false);
        }
    };

    // Debounced function to handle search input changes
    const debouncedSearch = useCallback(debounce((searchQuery) => {
        if (searchQuery) {  // Only trigger if there is input in the search query
            fetchProxy(searchQuery, 1);  // Reset to page 1 on search
        }
    }, 500), []);

    // Effect to trigger search when the query changes
    useEffect(() => {
        if (query) {
            debouncedSearch(query);
        }
    }, [query, debouncedSearch]);

    // Handle page change
    const handlePageChange = (newPage) => {
        fetchProxy(query, newPage);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mb: 2, gap: 2 }}>
            {/* Search Input */}
            <TextField
                variant="outlined"
                label="Search by User , Port , Location"
                value={query}
                onChange={(e) => setQuery(e.target.value)}  // Update query state
                sx={{
                    width: '300px',

                    // backgroundColor: 'white',  // White background
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#e0e0e0', // Light gray border color
                        },
                        '&:hover fieldset': {
                            borderColor: '#bdbdbd', // Darker gray on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9e9e9e', // Darkest gray when focused
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',  // Black text color
                    },
                    '& .MuiInputLabel-root': {
                        color: '#757575',  // Gray label color
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#616161',  // Dark gray label when focused
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon sx={{ color: 'white' }} />
                        </InputAdornment>
                    )
                }}
            />

            {loading && (
                <CircularProgress />
            )}

            {!loading && noResults &&
                <p>No results found</p>
            }

        </Box>
    );
};

export default SearchProxy;

