import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditSpecificReviewModal = ({ open, handleClose, reviewData, setRender, render }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [formData, setFormData] = useState({
        reviewText: '',
        location: '',
        rating: '',
        searchKeyword: '',
        link: ''
    });

    useEffect(() => {
        if (reviewData) {
            setFormData({ ...reviewData });
        }
    }, [reviewData]);

    const handleSave = () => {
        if (!formData.reviewText || !formData.rating || !formData.searchKeyword || !formData.link) {
            if (ToasterToggle) {
                toast.error('All fields must be filled out');
            }
            return;
        }

        axios.put(`${process.env.REACT_APP_BASE_URL}specific-review/${reviewData._id}`, formData)
            .then(() => {
                if (ToasterToggle) {
                    toast.success('Review updated successfully');
                }
                setRender(!render);
                handleClose();
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error('Error updating review');
                }
                console.error('Error updating review:', error);
            });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-review-modal-title"
                aria-describedby="edit-review-modal-description"
            >
                <Box sx={style}>
                    <Typography id="edit-review-modal-title" variant="h6" component="h2" style={{ color: "black" }}>
                        Edit Review
                    </Typography>
                    <TextField label="Review Text" variant="outlined" fullWidth margin="normal" value={formData.reviewText} onChange={(e) => setFormData({ ...formData, reviewText: e.target.value })} />
                    <TextField label="Rating" variant="outlined" fullWidth margin="normal" value={formData.rating} onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                    <TextField label="Location" variant="outlined" fullWidth margin="normal" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} />
                    <TextField label="Link" variant="outlined" fullWidth margin="normal" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                    <TextField label="Search Keyword" variant="outlined" fullWidth margin="normal" value={formData.searchKeyword} onChange={(e) => setFormData({ ...formData, searchKeyword: e.target.value })} />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default EditSpecificReviewModal;

