import React, { useEffect, useState } from 'react'
import DataTable from '../components/DataTable'
import { CircularProgress, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import axios from "axios"
import CreateProxy from './CreateProxy'
import Circular from '../components/Circular'
import ImportProxy from '../components/ImportProxy'
import { useNavigate } from 'react-router-dom'
import { MdMoreVert } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai"
import { ToastContainer, toast } from 'react-toastify';
import EditProxyModal from '../components/EditProxyModal'
import ProxyCategoriesMain from '../components/ProxyCategoriesMain'
import ProxyAssignCategoryModal from '../components/ProxyAssignCategoryModal'
import { BiCategoryAlt } from "react-icons/bi";

import { CgMoreVertical } from "react-icons/cg";
import ProxyAssignCategoryModalBulk from '../components/ProxyAssignCategoryModalBulk'

const Allproxies = () => {
    const URL = process.env.REACT_APP_BASE_URL
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [data, setData] = useState([])
    const [render, setrender] = useState(false)
    const navigate = useNavigate()
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentProxyData, setCurrentProxyData] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); // For menu handling
    const [currentRow, setCurrentRow] = useState(null); // Track the current row for actions
    const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([])
    const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false);
    const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false);

    const [selectedProxyForCategory, setSelectedProxyForCategory] = useState(null);

    const user = localStorage.getItem("user")
    useEffect(() => {
        console.log("use effect called")

        if (!user) {
            console.log("Not a user")
            navigate("/login")
        }

    }, [user])

    const handleOpenEditModal = (proxyData) => {
        setCurrentProxyData(proxyData);
        setIsEditModalOpen(true);
    };


    // const handleCloseEditModal = () => {
    //     setEditModalOpen(false);
    //     setSelectedProxy(null); // Clear selected proxy data
    // };

    useEffect(() => {
        axios.get(`${URL}proxy/allproxies`)
            .then((res) => {
                // console.log(res)
                setData(res.data.proxies)
            })
            .catch((error) => { console.log(error) })
    }, [render])
    // console.log(data)

    const handleDeleteProxy = (proxyId) => {
        // console.log("ID : ", proxyId)
        axios.delete(`${URL}proxy/deleteproxy/${proxyId}`)
            .then(() => {
                console.log('Proxy deleted successfully');
                if (ToasterToggle) { // Check if toaster should be displayed

                    toast.success('Proxy updated successfully');
                }
                setrender((prev) => !prev);
            })
            .catch((error) => {
                console.error('Error deleting proxy:', error)
                if (ToasterToggle) { // Check if toaster should be displayed

                    toast.error('Proxy updated successfully');
                }
            });
    };

    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };


    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };

    const handleDelete = () => {
        handleMenuClose();
        handleDeleteProxy(currentRow._id);
    };

    const onAssignToProxy = async (Data) => {
        // console.log("DATA : ", Data)
        setAssignMultipleToCategoty(Data)
        setAssignCategoryModalBulkOpen(true);
        handleMenuClose(); // Close the actions menu

    };

    const handleAssignCategory = (proxy) => {
        setSelectedProxyForCategory(proxy);
        setAssignCategoryModalOpen(true);
        handleMenuClose(); // Close the actions menu
    };

    const columns = [
        { field: "_id", headerName: "ID", flex: 1 },
        { field: "user", headerName: "User", flex: 1 },
        { field: "ip", headerName: "IP", flex: 1 },
        { field: "port", headerName: "Port", flex: 1 },
        { field: "password", headerName: "Password", flex: 1 },
        { field: "protocol", headerName: "Protocol", flex: 1 },
        { field: "location", headerName: "Location", flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            // Other properties...
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        },

    ]
    //  = (row) => {
    const flattenConnectedUsers = data.map((rowM, index) => {
        return {
            id: rowM._id,
            _id: rowM._id,
            user: rowM.user,
            ip: rowM.ip,
            port: rowM.port,
            password: rowM.password,
            location: rowM.location,
            protocol: rowM.protocol,
            isAssignCategory: rowM.isAssignCategory
        };
    });


    // const rows = responseUserData.reduce((acc, company) => {
    //     const flattenedUsers = flattenConnectedUsers(company);
    //     return [...acc, ...flattenedUsers.map((rowM, index) => ({ ...rowM, id: `${index}-${rowM._id}` }))];
    // }, []);
    // console.log("currentRow", currentRow)

    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditProxyModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                proxyData={currentProxyData}
                setrender={setrender} // Make sure to correct the function name if necessary
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5"> Proxies </Typography>

                <CreateProxy render={render} passSelectedCategoryId={passSelectedCategoryId} setrender={setrender} />
            </div>
            {/* <div sx={{ width: "100%" }} > */}
            <div>

                {data.length > 0 ? (
                    <>
                        <ProxyCategoriesMain render={render} setrender={setrender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenConnectedUsers} columns={columns} onAssignToProxy={onAssignToProxy} />
                        {/* <DataTable rows={flattenConnectedUsers} columns={columns} /> */}
                        {/* Place Menu here, outside DataTable but inside the overall layout */}
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleEdit}>
                                <ListItemIcon>
                                    <CiEdit fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>
                            </MenuItem>
                            {/* <MenuItem onClick={handleDelete}>
                                <ListItemIcon>
                                    <AiTwotoneDelete fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem> */}
                            {!currentRow?.isAssignCategory && (
                                <MenuItem onClick={() => handleAssignCategory(currentRow)}>
                                    <ListItemIcon>
                                        <BiCategoryAlt fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Assign Category</ListItemText>
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                ) : (
                    <Circular />
                )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "3rem" }}>
                <ImportProxy render={render} setrender={setrender} categoryId={passSelectedCategoryId} />
            </div>

            {assignCategoryModalOpen && (
                <ProxyAssignCategoryModal
                    open={assignCategoryModalOpen}
                    onClose={() => setAssignCategoryModalOpen(false)}
                    proxy={selectedProxyForCategory}
                    // multipleProxy={assignMultipleToCategory}
                    setRender={setrender}
                />
            )}

            {assignCategoryModalBulkOpen && (
                <ProxyAssignCategoryModalBulk
                    open={assignCategoryModalBulkOpen}
                    onClose={() => setAssignCategoryModalBulkOpen(false)}
                    multipleProxy={assignMultipleToCategory}
                    setRender={setrender}
                />
            )}

        </div>
    );

}

export default Allproxies
