import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';


const SettingCategoriesTable = ({ render, onCategorySelect }) => {
    const [categories, setCategories] = useState([]);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const allCategories = [{ _id: 'all', categoryName: 'All Categories' }, ...categories];

    useEffect(() => {
        fetchCategories();
    }, [render]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}category/all`);
            setCategories(response.data);
        } catch (error) {
            if (ToasterToggle) {
                toast.error('Failed to fetch categories');
            }
            console.error('Error fetching categories:', error);
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Category Name',
            flex: 4,
        },
    ];

    const rows = allCategories.map((category) => ({
        id: category._id,
        name: category.categoryName,
    }));


    const handleRowClick = (params) => onCategorySelect(params.id);

    return (
        <div style={{ height: 500, width: '20%', overflow: 'hidden', border: 'none', margin: "2rem", resize: 'vertical', minHeight: '200px' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                onRowClick={handleRowClick}
                hideFooter
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white',
                        border: "none",
                        fontSize: '1.25rem',
                        borderBottom: "Solid"
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                        border: "none",

                        '&.Mui-selected': {
                            backgroundColor: '#4A5568 !important',
                            '&:hover': {
                                backgroundColor: '#4A5568',
                            },
                        },
                    },

                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                        '& .MuiDataGrid-row': {
                            '&:last-child .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                        },
                    },
                    '& .MuiDataGrid-root': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            />
        </div>
    );
};

export default SettingCategoriesTable;

