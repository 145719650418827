import React from 'react';
import { Button, Modal, Box, Typography, DialogActions } from '@mui/material';

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, message }) => {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                color: "black"
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Confirm Deletion
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message || 'Are you sure you want to delete this?'}
                </Typography>
                <DialogActions sx={{ justifyContent: "flex-end", mt: 4 }}>
                    <Button onClick={onClose} variant="contained" color="error">No</Button>
                    <Button onClick={handleConfirm} variant="contained" color="success" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
};

export default ConfirmDeleteModal;
