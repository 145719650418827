import React from 'react'
import AccountModal from '../components/AccountModal'

const CreateAccount = ({ render, setRender, passSelectedCategoryId }) => {
    return (
        <div style={{ margin: "2rem 3rem" }} >
            <AccountModal ButtonName={"Create Account"} render={render} setRender={setRender} passSelectedCategoryId={passSelectedCategoryId} />
        </div>
    )
}

export default CreateAccount
