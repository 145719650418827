import React, { useEffect, useState } from 'react';
// import DataTable from '../components/DataTable';
import { CircularProgress, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import axios from "axios";
import CreateReview from '../components/review/CreateReview';
import Circular from '../components/Circular';
// import ImportReview from '../components/ImportReview';
import { useNavigate } from 'react-router-dom';
import { MdMoreVert } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';

import ReviewCategoriesMain from '../components/review/ReviewCategoriesMain';
// import ReviewAssignCategoryModal from '../components/ReviewAssignCategoryModal';
import { BiCategoryAlt } from "react-icons/bi";
import { CgMoreVertical } from "react-icons/cg";
import ImportReview from '../components/review/ImportReview';
import ChangeReviewCategoryModal from '../components/review/ChangeReviewCategoryModal';
import EditReviewModal from '../components/review/EditReviewModal';
// import ReviewAssignCategoryModalBulk from '../components/ReviewAssignCategoryModalBulk';

const AllReviews = () => {
    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [data, setData] = useState([]);
    const [render, setRender] = useState(false);
    const navigate = useNavigate();
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentReviewData, setCurrentReviewData] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([]);
    const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false);
    const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false);

    const [selectedReviewForCategory, setSelectedReviewForCategory] = useState(null);


    const user = localStorage.getItem("user");

    useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user]);

    const handleOpenEditModal = (reviewData) => {
        setCurrentReviewData(reviewData);
        setIsEditModalOpen(true);
    };

    useEffect(() => {
        axios.get(`${URL}review/all`)
            .then((res) => {
                setData(res.data.reviews);
            })
            .catch((error) => console.error(error));
    }, [render]);

    const handleDeleteReview = (reviewId) => {
        axios.delete(`${URL}review/deletereview/${reviewId}`)
            .then(() => {
                if (ToasterToggle) {
                    toast.success('Review deleted successfully');
                }
                setRender((prev) => !prev);
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error('Error deleting review');
                }
            });
    };

    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };

    const onAssignToReview = (data) => {
        setAssignMultipleToCategoty(data);
        setAssignCategoryModalBulkOpen(true);
        handleMenuClose();
    };

    const handleAssignCategory = (review) => {
        setSelectedReviewForCategory(review);
        setAssignCategoryModalOpen(true);
        handleMenuClose();
    };

    const columns = [
        { field: "_id", headerName: "ID", flex: 1 },
        { field: "reviewText", headerName: "Review Text", flex: 2 },
        { field: "searchKeyword", headerName: "Search Keyword", flex: 2 },
        { field: "location", headerName: "Location", flex: 1 },
        { field: "rating", headerName: "Rating", flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        }
    ];

    const flattenReviews = data.map((row) => ({
        id: row._id,
        _id: row._id,
        reviewText: row.reviewText,
        searchKeyword: row.searchKeyword,
        location: row.location,
        rating: row.rating,
    }));

    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditReviewModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                reviewData={currentReviewData}
                setRender={setRender}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">Reviews</Typography>
                <CreateReview render={render} passSelectedCategoryId={passSelectedCategoryId} setRender={setRender} />
            </div>
            {data.length > 0 ? (
                <>
                    <ReviewCategoriesMain render={render} setRender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenReviews} columns={columns} onAssignToReview={onAssignToReview} />

                    <ImportReview render={render} setRender={setRender} reviewCategoryId={passSelectedCategoryId} />
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                                <CiEdit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        {!currentRow?.isAssignCategory && (
                            <MenuItem onClick={() => handleAssignCategory(currentRow)}>
                                <ListItemIcon>
                                    <BiCategoryAlt fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Assign/Change Category</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </>
            ) : (
                <Circular />
            )}
            {assignCategoryModalOpen && (
                <ChangeReviewCategoryModal
                    open={assignCategoryModalOpen}
                    onClose={() => setAssignCategoryModalOpen(false)}
                    review={selectedReviewForCategory}
                    setRender={setRender}
                    render={render}
                />
            )}
            {/* {assignCategoryModalBulkOpen && (
                <ReviewAssignCategoryModalBulk
                    open={assignCategoryModalBulkOpen}
                    onClose={() => setAssignCategoryModalBulkOpen(false)}
                    multipleReviews={assignMultipleToCategoty}
                    setRender={setRender}
                />
            )} */}
        </div>
    );
};

export default AllReviews;

