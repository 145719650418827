import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Button } from '@mui/material';

const AllLinks = ({ setRender, Page, totalItems, rows, columns, handlePageChange, columnVisibilityModel, saveColumnVisibility }) => {
    const [limit] = useState(100);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [delData, setDeleteData] = useState([]);

    const handleBulkDelete = async (deleteData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}link/bulkdelete`, { ids: deleteData });
            toast.success(response.data.message || 'Selected links deleted successfully');
            setRender(prev => !prev);
        } catch (error) {
            toast.error(error.response.data.message || 'Failed to delete selected links');
        }
    };



    const ConfirmHandleDelete = (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData);
    };

    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData);
    };



    return (
        <div style={{ padding: '0.2rem' }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div style={{ width: '100%', height: '65vh' }}>
                <DataGrid
                    key={0}
                    page={Page - 1}
                    onPaginationModelChange={(newPage) => {
                        handlePageChange(newPage.page + 1);
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={limit}
                    rowCount={totalItems}
                    pagination
                    paginationMode="server"
                    getRowId={(row) => row._id}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => saveColumnVisibility(newModel)}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                            fontSize: '1.25rem',
                            textAlign: 'center',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            justifyContent: 'center',
                        },
                        '& .MuiButtonBase-root': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#334a8d', // Darker blue on hover
                            },
                        },
                        '& .MuiDataGrid-cell': {
                            justifyContent: 'center', // Center the content in each cell
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                        },
                        '& .MuiTablePagination-root': {
                            color: 'white',
                        },
                        '& .MuiPaginationItem-root': {
                            color: 'white',
                        },
                    }}
                />
            </div>
            <Button
                variant="contained"
                color="error"
                onClick={() => ConfirmHandleDelete(rowSelectionModel)}
                disabled={rowSelectionModel.length === 0}
                sx={{ mt: 2 }}
            >
                Delete Selected
            </Button>
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete these Links?"
            />

        </div>
    );
};

export default AllLinks;

