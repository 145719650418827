import React, { useEffect, useState } from 'react'
import KeywordsForm from '../components/KeywordsForm'
import ImportKeywords from '../components/ImportKeywords'
import AllKeywords from './AllKeywords'
import { Typography } from '@mui/material'

const Keywords = () => {
    const [render, setRender] = useState(true)

    return (
        <div>
            <Typography variant="h3"> Keywords </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "2rem" }}>
                <KeywordsForm render={render} setRender={setRender} />
            </div>
            <AllKeywords render={render} setRender={setRender} />
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "2rem" }}>
                <ImportKeywords />
            </div>
        </div>
    )
}

export default Keywords

