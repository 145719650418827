import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const AssignQuestionCategoryModalToAccountCategory = ({ open, onClose, category }) => {
    const [questionCategories, setQuestionCategories] = useState([]);
    const [selectedQuestionCategory, setSelectedQuestionCategory] = useState('');

    useEffect(() => {
        fetchquestionCategories();
    }, []);

    const fetchquestionCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}question-category/all-categories`);
            setQuestionCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch question categories');
            console.error('Error fetching question categories:', error);
        }
    };

    const handleAssign = async () => {
        try {
            console.log({
                categoryId: category._id,
                questionCategoryId: selectedQuestionCategory
            })
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}category/assign-question-category`, {
                categoryId: category.id,
                questionCategoryId: selectedQuestionCategory
            });
            toast.success('Question category assigned successfully');
            onClose();
        } catch (error) {
            toast.error('Failed to assign question category');
            console.error('Error assigning question category:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                color: 'black'
            }}>
                <Typography variant="h6" sx={{ mb: 2 }} >Assign Question Category</Typography>
                <FormControl fullWidth>
                    <InputLabel>Select Question Category</InputLabel>
                    <Select
                        value={selectedQuestionCategory}
                        label="Select Question Category"
                        onChange={(e) => setSelectedQuestionCategory(e.target.value)}
                    >
                        {questionCategories.map((questionCategory) => (
                            <MenuItem key={questionCategory._id} value={questionCategory._id}>
                                {questionCategory.categoryName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={handleAssign} style={{ marginTop: '1rem' }}>
                    Assign
                </Button>
            </Box>
        </Modal>
    );
};

export default AssignQuestionCategoryModalToAccountCategory;

