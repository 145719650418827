export const formatTime = (minutes) => {
    if (!minutes || isNaN(minutes)) {
        return 'N/A';
    }

    // Parse the string to integer
    const totalMinutes = parseInt(minutes, 10);

    // Calculate hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    // Construct the formatted time string
    let formattedTime = '';
    if (hours > 0) {
        formattedTime += `${hours} H${hours > 1 ? 's' : ''}`;
    }
    if (hours > 0 && remainingMinutes > 0) {
        formattedTime += ', ';
    }
    if (remainingMinutes > 0) {
        formattedTime += `${remainingMinutes} M${remainingMinutes > 1 ? 's' : ''}`;
    }

    return formattedTime;
};